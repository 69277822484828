<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container remove" @click="closePu">
          <!-- 팝업 -->
          <div class="modal_wrap">
            <div class="modal_radius">
              <!-- 내용영역 -->
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
                  <div class="content_area">
                    <div class="de_tit group">
                      <span class="t_pr">BUFF 입금주소</span>
                      <p>{{ address }}</p>
                    </div>
                    <div class="qr_zone group">
                      <qrcode-vue :value="address" :size="size" level="H"/>
<!--                      <qrcode :value="address" :options="{ width: 260 }"></qrcode>-->
<!--                      <img src="/assets/image/@qr_code_100.png" alt="임시QR이미지">-->
                      <!-- TODO : canvas 100px 고정 -->
<!--                       <canvas width="100" height="100"></canvas>-->
                    </div>
                  </div>
                </div>
              </div>
              <!-- //내용영역 -->
              <!-- 확인버튼 -->
              <div class="modal_btn bg_primary">
                <div class="btn_table">
<!--                  <div class="btn_col" @click="shareAddress">-->
<!--                    <button>주소공유</button>-->
<!--                  </div>-->
                  <div class="btn_col" @click="copyAddress">
                    <button>주소복사</button>
                  </div>
                </div>
              </div>
              <!-- //확인버튼 -->
              <!-- 닫기 -->
              <div class="modal_close" @click="closePopup">
                <button><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
          <!-- //팝업 -->
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "popupDeposit",

  data() {
    return {
      address: '',
      name: '',
      email: '',
      show: false,
      size: 100,
    }
  },

  components: {
    QrcodeVue,
  },

  methods: {
    shareAddress() {
      const shareData = {
        text: this.address, // 파일 배열
        title: '주소 공유하기',
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        navigator.share(shareData);
      }
    },
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true

      if(this.show) {
        this.getUserProfile((result) => {
          if(result.success) {
            this.address = result.userProfile.address
            this.name = result.userProfile.name
            this.email = result.userProfile.email
          } else {
            console.log(result.userProfile)
          }
        })
      }
    },

    copyAddress() {
      let tempInput = document.createElement('input')
      tempInput.value = this.address
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.$notify({ type: 'success', text: '복사되었습니다.' })
    },
  }




}
</script>

<style scoped>

</style>