<template>
  <div class="wrap">
    <!-- 텝영역 -->
    <PopupSelectYear ref="PopupSelectYear" @closePopup=closePopup :year="year" @change="changeYear" @selectYear="selectYear"></PopupSelectYear>
    <div style="position: fixed; width: 100%; background: white; z-index: 9">
      <div class="tablist pt-50">
        <ul class="tab_type1">
          <li class="tab_type1-item" :class="{'selected': listType==='PLAN'}"><a role="button" class="item_link" @click="tabBtn('PLAN')">목록</a></li>
          <li class="tab_type1-item" :class="{'selected': listType==='MY'}"><a role="button" class="item_link" @click="tabBtn('MY')">MY 스테이킹</a></li>
          <li class="tab_type1-item" :class="{'selected': listType==='HISTORY'}"><a role="button" class="item_link" @click="tabBtn('HISTORY')">내역</a></li>
        </ul>
      </div>
      <!-- //텝영역 -->
      <!-- 날짜선택영역 -->
      <div class="year_box" v-if="listType==='HISTORY'">
        <button class="year_btn" @click="openSelectYear">{{ this.year }}</button>
      </div>
      <!-- //날짜선택영역 -->
    </div>
    <!-- 목록내역 -->
    <div class="contents h102 scroll_list" v-if="listType==='PLAN'" @scroll="handleHistoryListScroll">
      <div class="container pt-110">
        <div class="row_25">
          <div class="none_content" v-if="planList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>진행중인 내역이 없습니다.</p>
          </div>
          <ul class="gallery pt-30 pb-90" v-else>
            <li v-for="plan in planList" :key="plan.stakingPlanIdx">
              <div class="gallery__content white">
                <div class="gallery__title mb-14">
                  <div class="img_box">
                    <img src="/assets/image/@gallery.png" alt="">
                  </div>
                  <div class="title">
                    <span class="text primary">{{ plan.stakingPlanName }}</span>
                  </div>
                  <div class="desc_area">
                    <a role="button" class="detail fill_btn_bk s_btn radius_s pl-15 pr-15"
                       @click="$router.push({ name : 'StakingPlanDetail', params: { 'idx': plan.stakingPlanIdx, 'listType': this.listType } })">
                      <span class="txt">자세히보기</span>
                    </a>
                  </div>
                </div>
                <table class="bbs__view underline type_1">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>기간</th>
                    <td><span>{{ formatDate(plan.stakingStartDate, 'date') }} ~ {{ formatDate(plan.stakingExpiryDate, 'date') }}</span></td>
                  </tr>
                  <tr v-if="plan.stakingPlanType === 'EXTRA'">
                    <th>참여기간</th>
                    <td><span>{{ formatDate(plan.participateStartDate, 'date') }} ~ {{ formatDate(plan.participateEndDate, 'date') }}</span></td>
                  </tr>
                  <tr v-if="plan.stakingPlanType === 'FIX'">
                    <th>고정이자</th>
                    <td class="primary"><span>{{ Math.trunc(plan.rewardPoint) }}</span>%</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //목록내역 -->
    <!-- MY스테이킹 목록내역 -->
    <div class="contents h102 scroll_list" v-if="listType==='MY'" @scroll="handleHistoryListScroll">
      <div class="container pt-110">
        <div class="row_25">
          <div class="none_content" v-if="myList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>진행중인 내역이 없습니다.</p>
          </div>
          <ul class="gallery pt-30 pb-90" v-else>
            <li v-for="list in myList" :key="list.stakingPlanIdx">
              <div class="gallery__content opacity">
                <div class="gallery__title mb-14">
                  <div class="img_box">
                    <img src="/assets/image/@gallery.png" alt="">
                  </div>
                  <div class="title">
                    <span class="text white">{{ list.stakingPlanName }}</span>
                  </div>
                  <div class="desc_area">
                    <a role="button" class="detail fill_btn_bk s_btn radius_s pl-15 pr-15"
                       @click="$router.push({ name : 'StakingMyDetail', params: { 'idx': list.stakingIdx, 'listType': this.listType } })">
                      <span class="txt">자세히보기</span>
                    </a>
                  </div>
                </div>
                <table class="bbs__view underline type_2">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>참여수량</th>
                    <td><span>{{ numberWithCommasAndDecimal(setDecimal(list.stakingAmount,10000)) }}</span>BUFF</td>
                  </tr>
                  <tr v-if="list.stakingPlanType === 'FIX'">
                    <th>고정이자</th>
                    <td>
                      <span>{{ Math.trunc(list.rewardPoint) }}</span>%
<!--                      <span style="font-weight: normal">(APY 365일 기준)</span>-->
                    </td>
                  </tr>
                  <tr>
                    <th>기간</th>
                    <td>{{ formatDate(list.stakingStartDate, 'date') }} ~ {{ formatDate(list.stakingExpiryDate, 'date') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //MY스테이킹 내역 -->
    <!-- 히스토리 목록내역 -->
    <div class="contents h154 scroll_list" v-if="listType==='HISTORY'" @scroll="handleHistoryListScroll">
      <div class="container pt-160">
        <div class="row_25">
          <div class="none_content" v-if="historyList.length === 0">
            <img src="/assets/image/ico_none.svg" alt="">
            <p>진행중인 내역이 없습니다.</p>
          </div>
          <ul class="gallery pt-30 pb-100" v-else>
            <li v-for="(list, index) in historyList" :key="list.stakingIdx" @click="$router.push({ name: 'StakingHistoryDetail', params: { idx: list.stakingIdx } })">
              <h3 class="gallery__month mb-20" v-if="!setDate(index)">{{ formatDate(list.stakingStartDate, 'month') }}</h3>
              <div class="gallery__content opacity">
                <div class="gallery__title mb-14">
                  <div class="img_box">
                    <img src="/assets/image/@gallery.png" alt="">
                  </div>
                  <div class="title">
                    <span class="text white">{{ list.stakingPlanName }}</span>
                  </div>
                  <div class="desc_area">
                    <span class="status type1" :class="{'type2' : list.status === 'DONE'}">{{ trans(list.status) }}</span>
                  </div>
                </div>
                <table class="bbs__view underline type_2">
                  <colgroup>
                    <col style="width: 7rem;">
                    <col>
                  </colgroup>
                  <tbody>
                  <tr>
                    <th>
                      <span v-if="list.status === 'DONE'">참여수량</span>
                      <span v-else>해지수량</span>
                    </th>
                    <td><span>{{ numberWithCommasAndDecimal(setDecimal(list.stakingAmount,10000)) }}</span>BUFF</td>
                  </tr>
                  <tr>
                    <th v-if="list.stakingPlanType === 'FIX' || list.status === 'DONE'">
                      <span v-if="list.stakingPlanType === 'FIX'">보상수량</span>
                      <span v-else>총보상금액</span>
                    </th>
                    <td v-if="list.stakingPlanType === 'FIX' || list.status === 'DONE'">
                      <span>{{ numberWithCommasAndDecimal(setDecimal(list.stakingRewardAmount,10000)) }}</span>BUFF
                    </td>
                  </tr>
                  <tr v-if="list.stakingPlanType === 'FIX'">
                    <th>고정이자</th>
                    <td>
                      <span>{{ Math.trunc(list.rewardPoint) }}</span>%
<!--                      <span style="font-weight: normal">(APY 365일 기준)</span>-->
                    </td>
                  </tr>
                  <tr>
                    <th>기간</th>
                    <td>{{ formatDate(list.stakingStartDate, 'date') }} ~ {{ formatDate(list.stakingExpiryDate, 'date') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- //히스토리 목록내역 -->
    <!-- 하단버튼 영역 -->
    <div class="fixed_container">
      <div class="sw_button_area">
        <div class="sw_button">
          <div class="btn_table">
            <div class="btn_col" @click="$router.replace({ name : 'LockupList' })">
              <button class="addStatus"><span class="sw_btn btn1">락업</span></button>
            </div>
            <div class="btn_col" @click="$router.replace({ name : 'StakingList' })">
              <button class="addStatus"><span class="sw_btn btn2">스테이킹</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //하단버튼 영역 -->
  </div>
</template>

<script>
import PopupSelectYear from "@/components/popup/PopupSelectYear";
export default {
  name: "StakingList",
  props: ['setHeader'],
  components: {
    PopupSelectYear
  },
  data(){
    return {
      listType: this.$route.query.listType ? this.$route.query.listType : 'PLAN',
      isSelectYearOpen: false,
      page: 1,
      limit: 5,
      planList: [],
      myList: [],
      historyList: [],
      year: '',
      total: 0,
    }
  },
  mounted() {
    this.setHeader('dashboard', '스테이킹')
    this.getList(this.listType)
    let d = new Date()
    this.year = d.getFullYear()
  },
  methods: {
    getDateDiff(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();
      const result = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      console.log(result)
      return result; // 밀리세컨 * 초 * 분 * 시 = 일
    },
    tabBtn(type){
      if( type === this.listType){
        return
      }
      this.page = 1
      this.getList(type)
    },
    handleHistoryListScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isAtTheBottom = scrollHeight === scrollTop + clientHeight;
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.handleLoadMore();
    },
    handleLoadMore() {
      if(this.listType === 'PLAN'){
        if (this.planList.length < this.total) {
          this.page = this.page + 1
          this.getList(this.listType)
        }
      } else if(this.listType === 'MY'){
        if (this.myList.length < this.total) {
          this.page = this.page + 1
          this.getList(this.listType)
        }
      } else if(this.listType === 'HISTORY'){
        if (this.historyList.length < this.total) {
          this.page = this.page + 1
          this.getList(this.listType)
        }
      }

    },
    changeYear(year){
      this.year = year
      console.log(year)
    },
    setDate(index) {
      let date = this.formatDate(this.historyList.stakingStartDate, 'month')
      index--
      if (index == -1) {
        return false
      }
      let date2 = this.formatDate(this.historyList.stakingStartDate, 'month')
      let result = date == date2
      return result
    },
    trans(value) {
      if (value === 'TERMINATE') {
        return '중도해지'
      } else if (value === 'CLOSE') {
        return '중도해지'
      } else if (value === 'DONE') {
        return '종료'
      }
    },
    queryChange(){
      this.$router.push({
        query: {listType: this.listType},
      });
    },
    getList(type){
      if(this.page === 1){
       this.planList = []
       this.myList = []
       this.historyList = []
      }
      let formData = new FormData()
      formData.append('page', this.page)
      formData.append('limit', this.limit)
      if(type === 'PLAN'){
        let d = new Date()
        this.year = d.getFullYear()
        this.listType = type
        this.queryChange()
        this.$get(`${this.$STAKING_PLAN}?${new URLSearchParams(formData).toString()}`, 'get_staking_plan', true, (result) => {
          console.log(result.data)
          this.total = result.total
          for (let i = 0; i < result.data.length; i++) {
            this.planList.push(result.data[i])
          }
        },(result) => {
          this.httpError(result)
        })
      } else if(type === 'MY'){
        let d = new Date()
        this.year = d.getFullYear()
        this.listType = type
        this.queryChange()
        this.$get(`${this.$STAKING_MY}?${new URLSearchParams(formData).toString()}`, 'get_staking_plan', true, (result) => {
          console.log(result.data)
          this.total = result.total
          for (let i = 0; i < result.data.length; i++) {
            this.myList.push(result.data[i])
          }
        },(result) => {
          this.httpError(result)
        })
      } else if(type === 'HISTORY'){
        this.listType = type
        this.queryChange()
        formData.append('year', this.year)
        this.$get(`${this.$STAKING_HISTORY}?${new URLSearchParams(formData).toString()}`, 'get_staking_history', true, (result) => {
          console.log(result)
          this.total = result.total
          for (let i = 0; i < result.data.length; i++) {
            this.historyList.push(result.data[i])
          }
        },(result) => {
          this.httpError(result)
        })
      }
    },
    selectYear(){
      this.isSelectYearOpen = false
      this.getList('HISTORY')
    },
    closePopup() {
      this.isSelectYearOpen = false
    },
    openSelectYear() {
      this.isSelectYearOpen = true
      this.$refs.PopupSelectYear.showPopup()
    },
  }
}
</script>

<style scoped>
.scroll_list{
  -ms-overflow-style: none;
}
.scroll_list::-webkit-scrollbar{
  display:none;
}
.scroll_list{
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}
.none_content{
  height: calc(100vh - 154px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.none_content p {
  font-size: 16px;
  text-align: center;
  margin: 12px;
  color: white;
}
</style>