<template>
  <div class="wrap">
    <!-- 해더 -->
    <div class="header clear">
      <div class="title">
        <span><img src="/assets/image/h_logo.svg" alt="buff"></span>
      </div>
      <div class="right">
        <button type="button" @click="$router.back()"><i class="icon-close"></i></button>
      </div>
    </div>
    <div class="onepage layout_top">
      <div class="password_area onepage_flex">
        <!-- <div class="password_align"> -->
        <div class="container">
          <div class="row_25">
            <h3 class="pin_tit">PIN</h3>
            <div class="guide_desc">
              <p>{{ comment }}</p>
            </div>
            <div class="password">
              <span id="key_1" class="word" :class="{ on: pinArr.length > 0 }"></span>
              <span id="key_2" class="word" :class="{ on: pinArr.length > 1 }"></span>
              <span id="key_3" class="word" :class="{ on: pinArr.length > 2 }"></span>
              <span id="key_4" class="word" :class="{ on: pinArr.length > 3 }"></span>
              <span id="key_5" class="word" :class="{ on: pinArr.length > 4 }"></span>
              <span id="key_6" class="word" :class="{ on: pinArr.length > 5 }"></span>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="password_keyboard">
        <div class="container">
          <table class="keyboard">
            <tbody>
            <tr>
              <td @click="addPin(1)">
                <button type="button" class="key">
                  <span>1</span>
                </button>
              </td>
              <td @click="addPin(2)">
                <button type="button" class="key">
                  <span>2</span>
                </button>
              </td>
              <td @click="addPin(3)">
                <button type="button" class="key">
                  <span>3</span>
                </button>
              </td>
            </tr>
            <tr>
              <td @click="addPin(4)">
                <button type="button" class="key">
                  <span>4</span>
                </button>
              </td>
              <td @click="addPin(5)">
                <button type="button" class="key">
                  <span>5</span>
                </button>
              </td>
              <td @click="addPin(6)">
                <button type="button" class="key">
                  <span>6</span>
                </button>
              </td>
            </tr>
            <tr>
              <td @click="addPin(7)">
                <button type="button" class="key">
                  <span>7</span>
                </button>
              </td>
              <td @click="addPin(8)">
                <button type="button" class="key">
                  <span>8</span>
                </button>
              </td>
              <td @click="addPin(9)">
                <button type="button" class="key">
                  <span>9</span>
                </button>
              </td>
            </tr>
            <tr>
              <td @click="pinArr = []">
                <button type="button" class="key">
                  <span class="cancel">취소</span>
                </button>
              </td>
              <td @click="addPin(0)">
                <button type="button" class="key">
                  <span>0</span>
                </button>
              </td>
              <td @click="pinArr.pop()">
                <button type="button" class="key">
                  <span class="ico_del"><em class="hide">지우기</em></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="pin_find">
        <router-link :to="{ name : 'PinFind', params: { authKey: this.authKey } }" v-show="pinStep !== 0 && pinStep !== 1">
          PIN 번호를 잊어버렸어요. <i class="icon-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VuePin",

  data() {
    return {
      pinSetting: '',
      pinConfirm: '',
      pinCheck: '',
      // 핀 설정: 0, 핀 설정 확인: 1, 핀 검증: 2
      pinStep: this.$route.params.isPinSet,
      withdrawInfo: {
        addressTo: this.$route.params.addressTo,
        amount: this.$route.params.amount,
        phoneCode: this.$route.params.phoneCode,
        otpCode: this.$route.params.otpCode,
      },
      authKey: this.$route.params.authKey,
      comment: '',
      pinArr: [],
      stakingInfo: {
        planIdx: this.$route.params.planIdx,
        amount: this.$route.params.amount,
        otpCode: this.$route.params.otpCode,
        btnType: this.$route.params.btnType,
      }
    }
  },

  props: ['setHeader'],

  created() {
    console.log(this.withdrawInfo)
    console.log(this.stakingInfo)

    if(this.$route.params.isPinSet === undefined) {
      this.$router.back()
      return
    }

    if(this.pinStep === '0') {
      this.comment = '핀 번호를 설정해주세요.'
    } else {
      this.comment = '핀 번호를 입력해주세요.'
    }
  },

  mounted() {
    this.setHeader('arrow', 'img')
  },

  methods: {
    addPin(no) {
      if(this.pinArr.length > 6) {
        return
      }

      this.pinArr.push(no)

      if(this.pinArr.length === 6) {

        if(this.pinStep === '0') {
          this.setPin()
        } else if(this.pinStep === '1') {
          this.confirmPin()
        } else if(this.pinStep === '2') {
          this.checkPin()
        }
      }
    },

    setPin() {
      this.pinSetting = this.pinArr.join('')
      this.comment = '핀 번호를 한 번 더 입력해주세요.'
      this.pinStep = '1'
      setTimeout(() => {
        this.pinArr = []
      }, 100)

    },

    confirmPin() {
      this.pinConfirm = this.pinArr.join('')

      if(this.pinSetting === this.pinConfirm) {
        let formData = new FormData()
        formData.append('pin', this.pinConfirm)
        formData.append('authKey', this.authKey)

        this.$post(this.$USER_PIN_INIT, 'postUserPinInit', formData, false, (result) => {
          console.log(result)
          this.$notify({ type: 'success', text: result.message })

          this.pinArr = []
          this.comment = '핀 번호를 입력해주세요.'
          this.pinStep = '2'
        }, (result) => {
          console.log(result)
        })
      } else {
        this.$notify({ type: 'error', text: '핀 번호가 다릅니다.' })
        this.pinArr = []
        this.comment = '핀 번호를 설정해주세요.'
        this.pinStep = '0'
      }
    },

    checkPin() {
      this.pinCheck = this.pinArr.join('')

      if(this.withdrawInfo.addressTo) {
        this.withdraw()
        return
      }

      if(this.stakingInfo.planIdx) {
        this.registerStaking()
        return
      }

      let formData = new FormData()
      formData.append('pin', this.pinCheck)
      formData.append('authKey', this.authKey)

      this.$post(this.$USER_PIN_AUTH, 'pinCheck', formData, false, (result) => {
        sessionStorage.setItem('accessToken', result.data[0].accessToken)
        sessionStorage.setItem('refreshToken', result.data[0].refreshToken)
        this.$router.replace({ name: 'Dashboard' })
      }, (result) => {
        this.$notify({ type: 'error', text: result.message })
        this.pinCheck = ''
        this.pinArr = []
      })
    },
    registerStaking(){
      if(!this.stakingInfo.btnType) {
        this.$notify({ type: 'error', text: `처음부터 다시 시도해 주세요.` })
        return
      }
      let formData = new FormData()
      formData.append('amount', this.stakingInfo.amount)
      formData.append('pin', this.pinCheck)
      formData.append('otpCode', this.stakingInfo.otpCode)

      // 스테이킹 신청
      if(this.stakingInfo.btnType === 'register'){
        formData.append('planIdx', this.stakingInfo.planIdx)
        this.$post(this.$STAKING_REGISTER, 'register_staking', formData, true, () => {
          this.$router.push({ name: 'StakingList', query: { listType : 'PLAN' } })
          this.$notify({ type: 'success', text: '스테이킹 신청이 완료되었습니다.' })
        }, (result) => {
          this.$router.push({ name: 'StakingList', query: { listType : 'PLAN' } })
          this.httpError(result)
        })
      // 스테이킹 추가
      } else if(this.stakingInfo.btnType === 'add'){
        formData.append('stakingIdx', this.stakingInfo.planIdx)
        this.$post(this.$STAKING_ADD, 'add_staking', formData, true, () => {
          this.$router.push({ name: 'StakingList', query: { listType : 'MY' } })
          this.$notify({ type: 'success', text: '스테이킹 추가가 완료되었습니다.' })
        }, (result) => {
          this.$router.push({ name: 'StakingList', query: { listType : 'MY' } })
          this.httpError(result)
        })
      // 스테이킹 해지
      } else if(this.stakingInfo.btnType === 'subtract') {
        formData.append('stakingIdx', this.stakingInfo.planIdx)
        this.$post(this.$STAKING_SUBTRACT, 'subtract_staking', formData, true, () => {
          this.$router.push({name: 'StakingList', query: { listType : 'MY' } })
          this.$notify({type: 'success', text: '스테이킹 해지가 완료되었습니다.'})
        }, (result) => {
          this.$router.push({name: 'StakingList', query: { listType : 'MY' } })
          this.httpError(result)
        })
      }
    },
    withdraw() {
      let formData = new FormData()
      formData.append('addressTo', this.withdrawInfo.addressTo)
      formData.append('amount', this.withdrawInfo.amount)
      formData.append('pin', this.pinCheck)
      formData.append('phoneCode', this.withdrawInfo.phoneCode)
      formData.append('otpCode', this.withdrawInfo.otpCode)

      this.$post(this.$MAIN_WITHDRAW, 'pinMainWithdraw', formData, true, (result) => {
        this.$notify({ type: 'success', text: result.message })
        this.$router.push({ name: 'Dashboard' })
      }, (result) => {
        this.httpError(result)
        this.$router.push({ name: 'Dashboard' })
      })
    },
  }
}
</script>

<style scoped>

</style>