<template>
  <div class="wrap">
    <!-- 해더 -->
    <div class="header clear">
      <div class="left">
        <button @click="$router.back()"><i class="icon-arrow-left"></i> </button>
      </div>
      <div class="title">
        <span>코인검색</span>
      </div>
    </div>
    <!-- //해더 -->
    <div class="search">
      <div class="container">
        <div class="search-input">
          <input type="text" placeholder="코인 심볼 검색">
          <!-- 검색어 입력시 출력 -->
          <button class="input-search"></button>
          <button class="input-close"></button>
        </div>
      </div>
    </div>
    <div class="search_result">
      <div class="container">
        <div class="row_25">
          <!-- <ul class="search_result_list pt-26">
              <li>전체</li>
              <li>BUFF</li>
              <li>REAP</li>
              <li>KLAY</li>
          </ul> -->
          <div class="none search_none">
            <img src="/assets/image/logo_disable.svg" alt="buff">
            <p class="pt-20">검색된 코인이 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoinSearchList"
}
</script>

<style scoped>

</style>