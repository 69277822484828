<template>
  <div class="wrap">
    <!-- 해더 -->
    <div class="header clear">
      <div class="right">
        <button type="button" @click="$router.back()"><i class="icon-close"></i> </button>
      </div>
    </div>
    <!-- //해더 -->
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">

            <!-- PIN 재설정 -->
            <div class="tit_area">
              <h2 class="title t_pr">PIN 재설정</h2>
            </div>
            <!-- //PIN 재설정 -->


            <!-- 비밀번호찾기 폼 -->
            <div class="content_area type2" v-if="authType === 'bunny'">
              <div class="group">
                <p class="info_desc_2">PIN 재설정을 위해 계정 비밀번호를 입력하세요.</p>
              </div>
              <div class="text_field group">
                <div class="basic_input">
                  <input type="password" placeholder="비밀번호 입력" v-model="password" />
                </div>
              </div>
            </div>
            <div class="content_area type2" v-if="authType !== 'bunny'">
            <div class="group">
              <p class="info_desc_2">버니프렌즈를 SNS로 가입하신 경우, PIN 재설정을 위해 관리자에게 문의해 주세요.</p>
            </div>
          </div>
          </div>
        </div>
      </div>
      <!-- //비밀번호찾기 폼 -->
      <!-- 하단 버튼 -->
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="resetPin">확인</button>
            </div>
          </div>
        </div>
      </div>
      <!-- //하단 버튼 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PinFind",
  data(){
    return {
      password: '',
      authKey: this.$route.params.authKey,
      isPinSet: 0,
      authType: ''
    }
  },
  mounted() {
    this.authType = sessionStorage.getItem('authType')
  },
  methods:{
    resetPin(){
      if (this.authType === 'bunny'){
        let formData = new FormData
        formData.append('authKey', this.authKey)
        formData.append('password', this.password)
        this.$post(this.$USER_PIN_RESET, 'reset_pin', formData, false, () => {
          this.$router.push({ name: 'Pin', params: { isPinSet: 0, authKey: this.authKey } })
        }, (result) => {
          this.httpError(result.data)
        })
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>

</style>