<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container">
          <!-- 팝업 -->
          <div class="modal_wrap">
            <div class="modal_radius">
              <!-- 내용영역 -->
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
                  <div class="content_area">
                    <div class="de_tit u_line pb-14 group">
                      <span class="t_pr">BUFF 락업상세</span>
                    </div>
                    <div class="de_desc group">
                      <ul class="list_desc" v-for="(info, infoIdx) in lockupInfo" :key="info.lockupIdx" v-show="lockupInfo.length">
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">락업</span>
                            <span class="dsec_dd">{{ info.lockupBalance }} {{ getCoinLabel() }}</span>
                          </p>
                        </li>
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">지급된 수량</span>
                            <span class="dsec_dd">{{ Number(info.lockupBalance) - Number(info.remain) }} {{ getCoinLabel() }}</span>
                          </p>
                        </li>
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">락업시작일</span>
                            <span class="dsec_dd">{{ formatDate(info.createdAt, 'date') }}</span>
                          </p>
                        </li>
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">락업해지</span>
                            <span class="dsec_dd">{{ info.expiryPerc }}%</span>
                          </p>
                        </li>
                        <hr class="hr_lg_10" v-show="lockupInfo.length - 1 !== infoIdx"/>
                      </ul>
                      <div class="card-more t-center" v-show="!lockupInfo.length">
                        <button>락업 내역이 없습니다.</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //내용영역 -->
              <!-- 확인버튼 -->
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col">
                    <button type="button" @click="closePopup">확인</button>
                  </div>
                </div>
              </div>
              <!-- //확인버튼 -->
              <!-- 닫기 -->
              <div class="modal_close">
                <button type="button" @click="closePopup"><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
          <!-- //팝업 -->
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
export default {
  name: "PopupLockupDetail",

  data() {
    return {
      lockupInfo: [],
      show: false
    }
  },

  methods: {
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true

      if(this.show) {
        this.$get(`${this.$MAIN_LOCKUP_DETAIL}?page=1&limit=100`, 'getPopupLockupDetail', true, (result) => {
          this.lockupInfo = result.data

          this.lockupInfo.forEach(info => {
            if(info.shareAmount === '0' || info.lockupBalance === '0') {
              info.expiryPerc = 0
            } else {
              info.expiryPerc = Number(info.shareAmount) / Number(info.lockupBalance) * 100
            }
          })
        }, (result) => {
          console.log(result)
        })
      }
    },
  }


}
</script>

<style scoped>
.toast_content {
  font-size: 1rem;
  margin-top: 35px;
  max-height: calc(70vh);
  overflow-y: scroll;
  padding: 0 20px 15px;
}

</style>