<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="toast_popup remove" @click="closePu">
        <div class="toast_wrap">
          <div class="toast_container">
            <div class="toast_row">
              <!-- 타이틀 -->
              <div class="ts_tit">
                <h2 class="title t_pr">기간 선택</h2>
              </div>
              <!-- 타이틀 -->
              <hr class="hr_lg_10">
              <!-- 내용 -->
              <div class="toast_content">
                <ul class="list_desc">
                  <li>
                    <p class="align_between">
                      <span class="dsec_dt readonly" aria-readonly="true">일간</span>
                    </p>
                  </li>
                  <li>
                    <p class="align_between">
                      <button class="dsec_dt" @click="openKLAY">월간</button>
                    </p>
                  </li>
                </ul>
              </div>
              <!-- //내용 -->
              <!-- 닫기 -->
              <div class="modal_close">
                <button type="button" @click="closePopup"><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
export default {
  name: "SelectDate",
  data(){
    return {
      show: false
    }
  },
  methods:{
    openKLAY(){
      this.$root.$refs.BuffModal.showDialog('KLAY 지갑을 생성하시겠습니까?', '취소', '지갑생성', ()=>{}, ()=>{})
    },
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },
    showPopup(){
      this.show = true
    }
  }
}
</script>

<style scoped>

</style>