<template>
  <div class="wrap">
    <!-- 해더 -->
    <div class="header clear">
      <div class="left" @click="$router.back()">
        <button><i class="icon-arrow-left"></i> </button>
      </div>
    </div>
    <!-- //해더 -->
    <div class="onepage layout_topBottom">
      <div class="onepage_flex">
        <div class="container">
          <div class="row_25">
            <!-- PIN 재설정 -->
            <div class="tit_area">
              <h2 class="title">PIN 재설정</h2>
            </div>
            <!-- //PIN 재설정 -->
            <!-- PIN 재설정 폼 -->
            <div class="content_area">
              <div class="text_field group">
                <div class="basic_input">
                  <input type="password" placeholder="현재 PIN 6자리" maxlength="6" v-model="pin" inputmode="numeric">
                </div>
                <div class="basic_input">
                  <input type="password" placeholder="새로운 PIN 6자리" maxlength="6" v-model="newPin" inputmode="numeric">
                </div>
                <div class="basic_input">
                  <input type="password" placeholder="새로운 PIN 확인" maxlength="6" v-model="newPinConfirm" inputmode="numeric">
                </div>
              </div>
              <div class="group">
                <p class="info_desc">PIN 변경을 위해 비밀번호를 입력하세요.</p>
              </div>
              <div class="text_field group">
                <div class="basic_input">
                  <input type="password" placeholder="비밀번호 확인" v-model="password" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //PIN 재설정 폼 -->
      <!-- 버튼영역 -->
      <div class="onepage_area">
        <div class="container">
          <div class="row_25">
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="changePin">PIN 변경</button>
            </div>
          </div>
        </div>
      </div>
      <!-- //버튼영역 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MypagePinReset",

  data() {
    return {
      pin: '',
      newPin: '',
      newPinConfirm: '',
      password: '',
    }
  },

  created() {
    console.log()
  },

  methods: {
    changePin() {
      if(!this.pin || !this.newPin || !this.newPinConfirm || !this.password) {
        this.$notify({ type: 'error', text: '빈 칸을 입력해주세요.' })
        return
      }

      if(this.newPin !== this.newPinConfirm) {
        this.$notify({ type: 'error', text: '새 핀이 일치하지 않습니다.' })
        return
      }

      let formData = new FormData()
      formData.append('pin', this.pin)
      formData.append('newPin', this.newPin)
      formData.append('password', this.password)
      this.$post(this.$MYPAGE_PIN_RESET, 'postMypagePinReset', formData, true, (result) => {
        this.$notify({ type: 'success', text: result.message })
        this.$router.back()
      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>