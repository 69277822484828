import { createRouter, createWebHashHistory } from 'vue-router'

import Dashboard from "../components/Dashboard"
import Login from "../components/Login"
import BunnyLogin from "@/components/BunnyLogin";
import Pin from "../components/Pin"
import PinFind from "@/components/PinFind";
import MypagePinReset from '../components/mypage/MypagePinReset'
import MypageLoginHistory from '../components/mypage/MypageLoginHistory'
import MypageMain from '../components/mypage/MypageMain'
import MypageOTPSetting from '../components/mypage/MypageOTPSetting'
import MypagePasswordReset from '../components/mypage/MypagePasswordReset'
import CoinSearchList from "@/components/payment/CoinSearchList";
import LockupList from "@/components/lockup/LockupList";
import LockupHistory from "@/components/lockup/LockupHistory";
import StakingList from "@/components/staking/StakingList";
import StakingPlanDetail from "@/components/staking/stakingDetail/PlanDetail";
import StakingMyDetail from "@/components/staking/stakingDetail/MyDetail";
import StakingHistoryDetail from "@/components/staking/stakingDetail/HistoryDetail";
import Chart from "@/components/Chart";

export const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/bunny/login',
            name: 'BunnyLogin',
            component: BunnyLogin,
        },
        {
            path: '/pin',
            name: 'Pin',
            component: Pin
        },
        {
            path: '/pin/find',
            name: 'PinFind',
            component: PinFind
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
            path: '/mypage/login/history',
            name: 'MypageLoginHistory',
            component: MypageLoginHistory
        },
        {
            path: '/mypage/pin/reset',
            name: 'MypagePinReset',
            component: MypagePinReset
        },
        {
            path: '/mypage/main',
            name: 'MypageMain',
            component: MypageMain
        },
        {
            path: '/mypage/otp/setting',
            name: 'MypageOTPSetting',
            component: MypageOTPSetting
        },
        {
            path: '/mypage/password/reset',
            name: 'MypagePasswordReset',
            component: MypagePasswordReset
        },
        {
            path: '/main/coin/search',
            name: 'CoinSearchList',
            component: CoinSearchList
        },
        // {
        //     path: '/otc/register',
        //     name: 'OfferRegister',
        //     component: OfferRegister,
        //     meta: { keepAlive: true }
        // },

      // 락업 관련 페이지
        {
            path: '/lockup/list',
            name: 'LockupList',
            component: LockupList
        },
        {
            path: '/lockup/history/:idx',
            name: 'LockupHistory',
            component: LockupHistory
        },
        // 스테이킹 관련 페이지
        {
            path: '/staking/list',
            name: 'StakingList',
            component: StakingList
        },
        {
            path: '/staking/plan/detail/:idx',
            name: 'StakingPlanDetail',
            component: StakingPlanDetail
        },
        {
            path: '/staking/my/detail/:idx',
            name: 'StakingMyDetail',
            component: StakingMyDetail
        },
        {
            path: '/staking/history/detail/:idx',
            name: 'StakingHistoryDetail',
            component: StakingHistoryDetail
        },
        {
            path: '/chart',
            name: 'Chart',
            component: Chart
        },
  ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView()
    }
})


