<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "BunnyLogin",
  props: ['setHeader'],
  data(){
    return {
    }
  },
  mounted() {
    this.bunnyLogin()
    this.setHeader()
  },
  methods: {
    bunnyLogin(){
      console.log(this.$route.query.data)
      let formData = new FormData
      formData.append('data', this.$route.query.data)
      this.$post(this.$USER_BUNNY_LOGIN, 'bunny_login', formData, false, (result) => {
        console.log('success')
        console.log(result)
        let data = result.data[0]
        sessionStorage.setItem('authType', result.data[0].authType)
        this.$router.push({ name: 'Pin', params: { isPinSet: data.isPinSet ? 2 : 0, authKey: data.authKey } })
      }, (result) => {
        console.log('fail')
        this.httpError(result)
      })
    }
  }
}
</script>

<style scoped>

</style>