<template>
  <div class="wrap">
    <div class="layout_topBottom">
      <!-- 프로필 설정 -->
      <div class="setbox_block">
        <div class="setbox_items">
          <div class="container">
            <div class="row_25">
              <ul class="set_list set_list-uesr">
                <li>
                  <span>아이디</span><span class="user_info">{{ email }}</span>
                </li>
                <li>
                  <span>연락처</span><span class="user_info">{{ phone }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- //프로필 사진 설정 -->
      <!-- 설정 -->
      <div class="setbox_contents">
        <div class="setbox_block">
          <div class="setbox_items">
            <div class="container">
              <div class="row_25">
                <ul class="set_list">
                  <!--                  <li>-->
                  <!--                    <router-link :to="{ name: 'MypagePasswordReset' }">-->
                  <!--                      <span>비밀번호 재설정</span><i class="icon-arrow-right"></i>-->
                  <!--                    </router-link>-->
                  <!--                  </li>-->
                  <li>
                    <router-link :to="{ name: 'MypageLoginHistory' }">
                      <span>로그인 기록</span><i class="icon-arrow-right"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'MypagePinReset' }">
                      <span>PIN 재설정</span><i class="icon-arrow-right"></i>
                    </router-link>
                  </li>
                  <li v-if="!isOTPAuth">
                    <router-link :to="{ name: 'MypageOTPSetting' }">
                      <span>OTP 등록</span><i class="icon-arrow-right"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="setbox_block">
          <div class="setbox_items">
            <div class="container">
              <div class="row_25">
                <ul class="set_list">
                  <li>
                    <span>OTP {{ isOTPAuth ? '사용중' : '미사용중' }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //설정 -->
      <!-- 로그아웃 -->
      <div class="btn_logout_wrap">
        <div class="container">
          <div class="row_25">
            <button class="one_btn l_btn fill_btn_bk radius_l" @click="logoutCf">로그아웃</button>
          </div>
        </div>
      </div>
      <!-- //로그아웃 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageMain",

  data() {
    return {
      email: '',
      isOTPAuth: '',
      phone: '',
    }
  },

  props: ['setHeader'],

  created() {
    this.getUserProfile((result) => {
      if (result.success) {
        this.email = result.userProfile.email
        this.isOTPAuth = result.userProfile.isOTPAuth
        this.phone = result.userProfile.phone
      } else {
        console.log(result.userProfile)
      }
    })
  },

  mounted() {
    this.setHeader('arrow', '마이페이지')
  },

  methods: {
    logoutCf(){
      this.$root.$refs.BuffModal.showDialog('로그아웃 하시겠습니까?', '취소', '확인', ()=>{
        this.logout()
      }, ()=>{})
    },
    logout() {
      this.$get(this.$MYPAGE_LOGOUT, 'logout', true, (result) => {
        this.$notify({type: 'success', text: result.message})
        sessionStorage.clear()
        this.$router.push({name: 'Login'})
      }, (result) => {
        sessionStorage.clear()
        this.$router.push({name: 'Login'})
        console.log(result)
      })
    },
  }
}
</script>

<style scoped>

</style>