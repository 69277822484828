<template>
  <div class="wrap">
    <!-- 인트로 -->
    <div class="onepage intro layout_bottom">
      <!-- 인트로 로고 -->
      <div class="onepage_flex flex flex_item_center">
        <div class="logo">
          <img src="/assets/image/intro.svg" alt="buff">
        </div>
      </div>
      <!-- 인트로 로고 -->
      <!-- 인트로 입력폼 -->
      <div class="onepage_b_area flex_end">
        <div class="container">
          <div class="row_25">
            <div class="text_field">
              <div class="basic_input">
                <input type="email" v-model="email" placeholder="아이디를 입력해 주세요."/>
              </div>
              <div class="basic_input">
                <input type="password" v-model="password" placeholder="비밀번호를 입력해 주세요." @keydown.enter="login">
              </div>
            </div>
            <div class="base_area">
              <button class="one_btn l_btn fill_btn_bk radius_l" @click="login">로그인</button>
<!--              <button class="one_btn l_btn fill_btn_bk radius_l" @click="bfLogin">버니 프렌즈 로그인</button>-->
            </div>
            <div class="base_area">
              <div class="find_wrap">
                <a role="button" @click="popupOpen(0)">아이디 찾기</a>
                <a role="button" @click="popupOpen(1)">비밀번호 찾기</a>
              </div>
              <div class="find_wrap pt-10">
                <a role="button" @click="popupOpen(2)">SNS 로그인 사용자</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //인트로 입력폼 -->
    </div>
    <!-- //인트로 -->
    <popupLogin @closeLoginPopup="openLoginPopup=false" :openLoginPopup="openLoginPopup"
                :popupType="popupType"/>
  </div>
</template>

<script>
import popupLogin from "@/components/popup/popupLogin";
export default {
  name: "VueLogin",
  components: {
    popupLogin
  },
  data() {
    return {
      email: '',
      password: '',
      openLoginPopup: false,
      popupType: '',
      test: {"id": "jantest01","key": "test1234","reqTime": ''},
      loginData: ''
    }
  },

  props: ['showHeader'],

  created() {
    sessionStorage.clear()
  },

  mounted() {
    this.showHeader(false)
  },

  beforeUnmount() {
    this.showHeader(true)
  },

  methods: {
    // bfLogin(){
    //   this.$router.push({ name: 'BunnyLogin', query: { data: 'FhLk8qGVPLbDok/n64AmcEk/p2gdXFPJ0RNYEBGeQ1WRVcTtBSjlyf7IM6BU2ckMtcXo2RZts6dHE/qckF20uw4cZmTRlQYeyL+YREUzs+0=' }})
    // },
    popupOpen(type){
      this.popupType = type
      this.openLoginPopup = true
    },
    login() {
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.password)

      this.$post(this.$USER_LOGIN, 'postLogin', formData, false, (result) => {
        let data = result.data[0]
        sessionStorage.setItem('authType', result.data[0].authType)
        console.log(result.data)
        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)
        this.$router.push({ name: 'Pin', params: { isPinSet: data.isPinSet ? 2 : 0, authKey: data.authKey } })
      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style scoped>

</style>