<template>
  <div class="wrap">
    <AddPopup ref="AddPopup" @closePopup=closePopup :planDetail="planDetail" :btnType="btnType"></AddPopup>
    <SubtractPopup ref="SubtractPopup" @closePopup=closePopup :planDetail="planDetail" :btnType="btnType"></SubtractPopup>
    <!-- 해더 -->
    <div class="header clear">
      <div class="title"><span>스테이킹 상세</span></div>
      <div class="right">
        <button type="button" @click="$router.back()"><i class="icon-close"></i> </button>
      </div>
    </div>
    <!-- //해더 -->
    <!-- 메인 -->
    <div class="layout_topBottom" v-if="planDetail">
      <div class="container">
        <!-- 상세해더 -->
        <div class="pt-30  pb-30 row_25">
                    <span class="logo_area">
                        <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
          <span class="dp_title mt-20">{{ planDetail.stakingPlanName }}</span>
          <span class="dp_state mt-10" v-if="planDetail.stakingPlanType === 'FIX'">
            {{ Math.trunc(planDetail.rewardPoint) }}% BUFF 지급
<!--            <em class="comment mt-5">(APY 365일 기준)</em>-->
          </span>
          <span class="dp_state mt-10" v-if="planDetail.stakingPlanType === 'EXTRA'">추가보상</span>
        </div>
        <!-- //상세해더 -->
        <!-- 상세내역 -->
        <div class="row_25">
          <table class="bbs__view bbs__view2 underline type_1">
            <colgroup>
              <col style="width: 8rem;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>기간</th>
              <td>{{ formatDate(planDetail.stakingStartDate, 'date') }} ~ {{ formatDate(planDetail.stakingExpiryDate, 'date') }}</td>
            </tr>
            <tr v-if="planDetail.stakingPlanType === 'EXTRA' && listType === 'PLAN'">
              <th>참여기간</th>
              <td>
                {{ formatDate(planDetail.participateStartDate, 'date') }} ~ {{ formatDate(planDetail.participateEndDate, 'date') }}
                <em class="comment">참여기간이 지난 경우 해당 스테이킹<br>참여가 불가능 합니다.</em></td>
            </tr>
            <tr v-if="planDetail.stakingPlanType === 'FIX' || listType === 'MY'">
              <th>보상주기</th>
              <td>
                <span v-if="planDetail.stakingPlanType === 'FIX'">Day 지급</span>
                <span v-if="planDetail.stakingPlanType === 'EXTRA'">스테이킹 종료 후</span>
              </td>
            </tr>
            <tr v-if="planDetail.stakingRewardTicker === 'BUFF'">
              <th>지급방법</th>
              <td>코인수량</td>
            </tr>
            <tr v-if="planDetail.stakingRewardTicker === 'KRW' && parseInt(planDetail.rewardPoint) > 0">
              <th>총보상금액</th>
              <td>{{ numberWithCommas(setDecimal(parseInt(planDetail.rewardPoint),1)) }} 원</td>
            </tr>
            <tr>
              <th>참여수량</th>
              <td>
                <span>{{ numberWithCommasAndDecimal(setDecimal(parseFloat(planDetail.stakingAmount),10000)) }}</span>BUFF<br>
              </td>
            </tr>
            <tr v-if="planDetail.stakingPlanType === 'EXTRA'">
              <th>해지</th>
              <td>스테이킹 해지 시 보상이<br>지급되지 않습니다.</td>
            </tr>
            </tbody>
          </table>
          <p class="info_desc_2 t-center w-100 mb-30" v-if="planDetail.stakingPlanType === 'FIX'">
            <span v-if="listType === 'PLAN'">스테이킹을 통해 디지털 자산을<br>정책에 따라 보상으로 받아보세요.</span>
            <span v-if="listType === 'MY'">스테이킹 해지 시 해지 당일로부터<br>보상이 지급되지 않습니다.</span>
          </p>
          <div class="underline type_1" v-if="planDetail.description">
            <p class="info_desc_3 mt-30">
              {{ planDetail.description }}
            </p>
          </div>
          <div class="mt-20">
            <button class="fill_btn_pr l_btn radius_l one_btn mb-20" @click="openAddPopup('add')"
            v-if="today !== formatDate(planDetail.stakingExpiryDate, 'date') && planDetail.stakingPlanType === 'FIX'">스테이킹 추가</button>
            <button class="fill_btn_pr l_btn radius_l one_btn mb-20" @click="openAddPopup('add')"
            v-if="today <= formatDate(planDetail.participateEndDate, 'date') && planDetail.stakingPlanType === 'EXTRA'">스테이킹 추가</button>
            <button class="fill_btn_pr l_btn radius_l one_btn" @click="openSubtractPopup('subtract')">스테이킹 해지</button>
          </div>
        </div>
        <!-- //상세내역 -->
      </div>
    </div>
    <!-- //메인 -->
  </div>
</template>

<script>
import AddPopup from "@/components/staking/stakingPopup/AddPopup";
import SubtractPopup from "@/components/staking/stakingPopup/SubtractPopup";
export default {
  name: "StakingMyDetail",
  components: {
    AddPopup,
    SubtractPopup
  },
  data(){
    return {
      isPopupOpen: false,
      idx: this.$route.params.idx,
      listType: this.$route.params.listType,
      planDetail: '',
      isOTPAuth: false,
      btnType: '',
      today: '',
    }
  },
  mounted() {
    console.log(this.idx)
    this.getPlanDetail()
    this.getUserInfo()
  },
  methods: {
    getDateDiff(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();
      const result = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      console.log(result)
      return result; // 밀리세컨 * 초 * 분 * 시 = 일
    },
    getUserInfo() {
      this.getUserProfile((result) => {
        this.isOTPAuth = result.userProfile.isOTPAuth
      })
    },
    getPlanDetail(){
      this.$get(`${this.$STAKING_MY}/${this.idx}`, 'get_my_staking_detail', true, (result) => {
        console.log(result.data)
        this.planDetail = result.data[0]
        let d = new Date()
        this.today = this.dateFormat(d)
      },(result) => {
        this.httpError(result)
      })
    },
    closePopup() {
      this.isPopupOpen = false
    },
    openSubtractPopup(btnType) {
      this.btnType = btnType
      if (this.isOTPAuth) {
        this.isPopupOpen = true
        this.$refs.SubtractPopup.showPopup()
      } else {
        this.$router.push({name: 'MypageOTPSetting'})
        this.$notify({type: 'error', text: '스테이킹 신청은 OTP 등록이 완료되어야 가능합니다.'})
      }
    },
    openAddPopup(btnType) {
      this.btnType = btnType
      if (this.isOTPAuth) {
        this.isPopupOpen = true
        this.$refs.AddPopup.showPopup()
      } else {
        this.$router.push({name: 'MypageOTPSetting'})
        this.$notify({type: 'error', text: '스테이킹 신청은 OTP 등록이 완료되어야 가능합니다.'})
      }
    },
  }
}
</script>

<style scoped>

</style>