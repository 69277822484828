<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="toast_popup remove" @click="closePu">
        <div class="toast_wrap" @click="closePu">
          <div class="toast_container">
            <div class="toast_row">
              <!-- 타이틀 -->
              <div class="ts_tit">
                <h2 class="title t_pr">연도 선택</h2>
              </div>
              <!-- 타이틀 -->
              <hr class="hr_lg_10">
              <!-- 내용 -->
              <div class="toast_content">

                <ul class="list_desc2 year_container">
                  <li v-for="(item, index) in yearList"
                      :key="index"
                      :value="item.value"
                      :class="{'focus': selectedYear === item.text}"
                       @click="selectedYear=item.text">
                    <span>{{ item.text }}</span>
                  </li>
                </ul>

              </div>
              <!-- //내용 -->
              <!-- 선택 -->
              <div class="mt-10">
                <button class="fill_btn_bk l_btn radius_l one_btn" type="button"  @click="changeYear(selectedYear)">선택</button>
              </div>
              <!-- //선택 -->
              <!-- 닫기 -->
              <div class="modal_close">
                <button type="button" @click="closePopup"><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
export default {
  name: "PopupSelectYear",
  props: ['year'],
  data() {
    return {
      show: false,
      yearList: [],
      selectedYear: '',
    }
  },
  mounted() {
    this.setYear()
  },
  methods: {
    changeYear(year){
      this.selectedYear = year
      this.$emit('change', this.selectedYear )
      this.show = false
      this.$emit('selectYear')
    },
    setYear(){
      const nowYear = new Date().getFullYear();
      for (let i = 0; i < 3; i++) {
        let date = nowYear - i;
        this.yearList.push({ value: date, text: date });
      }
      console.log(this.yearList)
    },
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },
    showPopup() {
      this.show = true
      this.selectedYear = this.year
      console.log(this.year)
    },
  }
}
</script>

<style scoped>
.year_container{
  -ms-overflow-style: none;
}
.year_container::-webkit-scrollbar{
  display:none;
}
.year_container{
  max-height: 174px;
  overflow: scroll;
  overflow-x: hidden;
}
</style>