<template>
  <Line v-if="loaded"
        :data="chartData"
        :options="options"
        style="padding-bottom: 20px"
  />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)
export default {
  name: "Chart",
  components: {
    Line
  },
  props: [
    'referenceDate',
    'chartDateType'
  ],
  data () {
    return {
      currencyTicker: 'BUFF',
      exchangeTicker: 'KRW',
      date: [],
      price: [],
      loaded: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'KRW',
            backgroundColor: "var(--dark-gray02);",
            pointBackgroundColor: "var(--dark-gray02);",
            // fill: true,
            tension: 0.1,
            borderColor: "var(--dark-gray02);",
            pointBorderColor: "var(--dark-gray02);",
            borderWidth: 1,
            pointBorderWidth: 1,
            data: []
          },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              boxWidth: 8,
              padding: 10,
              usePointStyle: true,
              pointStyle: "circle",
              font: {
                size: 14
              }
            },
            fullSize: true,
            align: "center"
          },
          tooltip: {
            boxWidth: 15,
            bodyFont: {
              size: 14
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
              color: "#ffffff",
              lineWidth: 0.5,
            },
            border: {
              display: true,
              color: "rgba(255,255,255,0.7)",
              dash: [5, 5],
            },
            ticks: {
              padding: 5,
              color: "#ffffff",
              borderColor: "#ffffff",
              // drawBorder: false,
            }
          },
          y: {
            suggestedMax: '',
            suggestedMin: '',
            position: "right",
            ticks: {
              maxTicksLimit: 6,
              color: "#ffffff",
              callback: (val) => {
                return val !== 0 ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
              },
              padding: 10
            },
            grid: {
              color: "#ffffff",
              lineWidth: 0.5,
              // drawTicks: false,

            },
            border: {
              display: true,
              color: "rgba(255,255,255,0.7)",
              dash: [5, 5],
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 5000
        }
      }
    }
  },
 methods:{
    getChartData(){
      this.loaded = false
      this.date = []
      this.price = []
      let formData = new FormData()
      formData.append('chartDateType', this.chartDateType)
      if(this.chartDateType === 'MONTHLY'){
        // this.options.scales.y.ticks.stepSize = 50
        let date = this.referenceDate.substr(0,7)
        formData.append('referenceDate', date)
      } else {
        // this.options.scales.y.ticks.stepSize = 5
        formData.append('referenceDate', this.referenceDate)
      }
      formData.append('currencyTicker', this.currencyTicker)
      formData.append('exchangeTicker', this.exchangeTicker)
      this.$get(`${this.$MAIN_CHART}?${new URLSearchParams(formData).toString()}`, 'get_chart_data', true, (result) => {
        let data = result.data
        console.log(data)
        this.chartData.datasets[0].data = []
        for (let i = 0; i < data.length; i++) {
          if(this.chartDateType === 'MONTHLY'){
            this.date.push(data[i].month)
          } else {
            this.date.push(data[i].day)
          }
          let setNumber = data[i].exchangeRate.split('.')
          this.price.push(setNumber[0])
        }
        this.chartData.labels = this.date
        this.chartData.datasets[0].data = this.price
        this.loaded = true
        let max = Math.max.apply(null, this.price)
        let min = Math.min.apply(null, this.price)
        this.options.scales.y.suggestedMax = Math.ceil(max / 10) * 10 + 20
        if(min <= 20){
          this.options.scales.y.suggestedMin = Math.floor(min / 10) * 10
        } else {
          this.options.scales.y.suggestedMin = Math.floor(min / 10) * 10 - 20
        }
      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    referenceDate(){
      if(this.referenceDate){
        this.getChartData()
      }
    },
    chartDateType(){
      if(this.chartDateType){
        this.getChartData()
      }
    }

  }
}
</script>

<style scoped>
</style>