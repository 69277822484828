<template>
  <div class="wrap" v-if="show">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container">
          <!-- 팝업 -->
          <div class="modal_wrap">
            <div class="modal_radius">
              <!-- 내용영역 -->
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
                  <div class="content_area">
                    <div class="de_tit u_line pb-14 group">
                      <span class="t_pr">{{ lockupDetail.lockupPlanName }}</span>
                    </div>
                    <div class="de_desc group">
                      <ul class="list_desc">
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">락업수량</span>
                            <span class="dsec_dd"><span class="data">{{ numberWithCommasAndDecimal(setDecimal(lockupDetail.lockupAmount,10000)) }}</span>BUFF</span>
                          </p>
                        </li>
                        <li>
                          <p class="align_between flex_item_top">
                            <span class="dsec_dt">락업기간</span>
                            <span class="dsec_dd">
                              <span>{{ formatDate(lockupDetail.lockupStartDate, 'date') }} ~ {{ formatDate(lockupDetail.lockupExpiryDate, 'date') }}</span>
                              <span>({{ date }}일)</span>
<!--                              <span v-else>(당일)</span>-->
                            </span>
                          </p>
                        </li>
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">락업보상</span>
                            <span class="dsec_dd"><span class="data">{{ parseFloat(lockupDetail.rewardRate) }}</span>%</span>
                          </p>
                        </li>
                        <li>
                          <p class="align_between">
                            <span class="dsec_dt">지급비율</span>
                            <span class="dsec_dd"><span class="data">{{ 100/lockupDetail.lockupRatio }}</span>%</span>
                          </p>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <!-- //내용영역 -->
              <!-- 확인버튼 -->
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col">
                    <button @click="closePopup">확인</button>
                  </div>
                </div>
              </div>
              <!-- //확인버튼 -->
              <!-- 닫기 -->
              <div class="modal_close">
                <button @click="closePopup"><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
          <!-- //팝업 -->
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
export default {
  name: "LockupPopup",
  props: ['lockupDetail'],
  data() {
    return {
      show: false,
      date: '',
    }
  },
  methods: {
    getDateDiff(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();
      const result = Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1)
      this.date = result
    },
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },

    showPopup() {
      this.show = true
    },
  },
  watch: {
    lockupDetail(){
      if(this.lockupDetail){
        this.getDateDiff(
            this.formatDate(this.lockupDetail.lockupStartDate, 'date'),
            this.formatDate(this.lockupDetail.lockupExpiryDate, 'date')
        )
      }
    }
  }
}
</script>

<style scoped>

</style>