<template>
  <div class="wrap">
    <div class="layout_topBottom">
      <div class="content_area">
        <div class="container">
          <div class="row_25 login-history">
            <table class="login_rog">
              <thead>
              <tr>
                <th class="rog_h">날짜</th>
                <th class="rog_h">브라우저</th>
                <th class="rog_h">IP</th>
<!--                <th class="rog_h">위치</th>-->
              </tr>
              </thead>
              <tbody>
                <tr v-for="history in loginHistory" :key="history.date">
                  <td class="rog_td">{{ history.formatDate }}</td>
                  <td class="rog_td">{{ history.browser }}</td>
                  <td class="rog_td">{{ history.address }}</td>
<!--                  <td class="rog_td">-->
<!--                    <button class="fill_btn_pr s_btn radius_s ml-10">-->
<!--                      <a href="https://nordvpn.com/ko/ip-lookup/" target="_blank" style="color: white; font-weight: normal">위치</a>-->
<!--                    </button>-->
<!--                  </td>-->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <paginate v-show="loginHistory.length"
                :page-count="pageLast"
                :page-range="3"
                :margin-pages="2"
                :click-handler="getList"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination'"
                :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
  name: "LoginHistory",

  data() {
    return {
      loginHistory: [],
      page: 1,
      size: 10,
      pageLast: 0,
    }
  },

  props: ['setHeader'],

  components: {
    paginate: Paginate,
  },

  created() {
    this.getList(this.page)
  },

  mounted() {
    this.setHeader('arrow', '로그인 기록')
  },

  methods: {
    getList(page) {
      this.page = page
      let params = new URLSearchParams()
      params.append('page', page)
      params.append('limit', this.size)

      this.$get(`${this.$MYPAGE_LOGIN_HISTORY}?${params.toString()}`, 'getMypageLoginHistory', true, (result) => {
        this.pageLast = Math.ceil(result.total / this.size)

        this.loginHistory = result.data
        this.loginHistory.forEach(history => {
          history.formatDate = this.formatDate(history.date)
        })
      }, (result) => {
        console.log(result)
      })
    }
  },

  watch: {
    '$route.query.page'() {
      this.getList(this.$route.query.page)
    },
  },
}

</script>

<style>
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center ;
  margin-top: 30px;
  text-align: center;
  bottom: 0px;
  position: absolute;
  width: 100%;
}

.pagination li {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
  cursor: pointer;
  background-color: transparent;
  margin: 0 2px;
}

.page-item.active .page-link {
  background-color: white !important;
  border-color: white !important;
}

.page-link {
  color: #333 !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
}

.login-history {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
</style>