<template>
  <div class="wrap" v-show="openLoginPopup">
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="layout_popup">
        <div class="layout_container">
          <!-- 팝업 -->
          <div class="modal_wrap">
            <div class="modal_radius">
              <!-- 내용영역 -->
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/h_logo.svg" alt="buff">
                    </span>
                  <div class="content_area">
                    <p class="ment" v-show="popupType===0">BUFF 락업 지갑은 버니프렌즈에 등록된<br>핸드폰 번호로 로그인이 가능합니다.</p>
                    <p class="ment" v-show="popupType===1">비밀번호는 버니프렌즈 앱을 통해<br>비밀번호 찾기가 가능합니다.</p>
                    <p class="ment" v-show="popupType===2">버니프렌즈를 SNS로 가입하신 경우<br>버니프렌즈 앱에서만<br>지갑 서비스를 이용하실 수 있습니다.</p>
                  </div>
                </div>
              </div>
              <!-- //내용영역 -->
              <!-- 확인버튼 -->
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col">
                    <button type="button" @click="$emit('closeLoginPopup')">확인</button>
                  </div>
                </div>
              </div>
              <!-- //확인버튼 -->
            </div>
          </div>
          <!-- //팝업 -->
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
export default {
  name: "LoginPopup",
  props: [
    'openLoginPopup',
    'popupType',
  ],
}
</script>

<style scoped>

</style>