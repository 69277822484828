<template>
  <div id="app">
    <Header ref="Header"></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :showHeader="showHeader" :setHeader="setHeader" :setDashboardHeader="setDashboardHeader"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :showHeader="showHeader" :setHeader="setHeader" :setDashboardHeader="setDashboardHeader"></router-view>
    <notifications>
      <template #body="props">
        <div class="my-notification" :class="{ success: props.item.type === 'success', error: props.item.type === 'error' }">
          <button>
            <i class="fa fa-check-circle mr-1" v-show="props.item.type === 'success'"></i>
            <i class="fa fa-exclamation-circle mr-1" v-show="props.item.type === 'error'"></i>
          </button>
          <span class="notification-content">{{ props.item.text }}</span>
        </div>
      </template>
    </notifications>
    <BuffModal ref="BuffModal"/>
  </div>
</template>

<script>
import Header from "./components/cmp/Header"
import BuffModal from "@/components/cmp/modal/BuffModal";

export default {
  name: 'App',
  components: {
    'Header': Header,
    'BuffModal': BuffModal,
  },

  created () {
    document.title = process.env.VUE_APP_TITLE

    let accessToken = sessionStorage.getItem('accessToken')
    if(accessToken) {
      this.refreshToken()
    }
  },
  mounted() {
    this.$root.$refs.BuffModal = this.$refs.BuffModal
  },

  methods: {
    showHeader(state) {
      this.$refs.Header.showHeader(state)
    },

    setHeader(btn, tit) {
      this.$refs.Header.setHeader(btn, tit)
    },

    setDashboardHeader() {
      if(!this.$refs.Header) {
        this.$router.push({ name: 'Pin', params: { isPinSet: 2 } })
        return
      }
      this.$refs.Header.setDashboardHeader()
    }
  }
}
</script>

<style>
.vue-notification-group {
  top: calc(100vh / 2) !important;
  left: 50%;
  max-width: 400px !important;
  transform: translate(-50%, -50%);
}

.my-notification {
  display: flex;
  justify-content: center;
  margin: 0 5px 5px;
  padding: 10px;
  border-radius: 5px;
  opacity: 0.7;
}

.my-notification button {
  color: white;
  margin-right: 0.5rem;
}

.my-notification .notification-content {
  color: white;
  font-size: 14px;
}

.my-notification.success {
  background: var(--alert-success);
 }

.my-notification.error {
  background: var(--alert-fail);
 }


</style>
