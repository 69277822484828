<template>
  <div class="wrap" v-if="show">
    <StakingTerms ref="StakingTerms"></StakingTerms>
    <!-- 팝업전체영역 -->
    <div class="bg_overlay">
      <div class="layout_popup remove" @click="closePu">
        <div class="layout_container remove" @click="closePu">
          <!-- 팝업 -->
          <div class="modal_wrap">
            <div class="modal_radius">
              <!-- 내용영역 -->
              <div class="modal_content bg_wh">
                <div class="madal_row">
                    <span class="logo_area">
                      <img src="/assets/image/ico_buff.svg" alt="BUFF">
                    </span>
                  <div class="content_area">
                    <!-- 신청가능 수량 -->
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>신청가능 수량</h4>
                      </div>
                      <div class="basic_input unit_input unit_input2 disabled">
                        <input type="text" id="number" :value="availableBalance" disabled>
                        <span class="unit">BUFF</span>
                      </div>
                    </div>
                    <!-- 추가수량 -->
                    <div class="group">
                      <div class="m_input_tit">
                        <h4>추가수량</h4>
                      </div>
                      <div class="basic_input unit_input unit_input3">
                        <input type="number" placeholder="수량 입력" v-model.number="amount" @input="handleInput" inputmode="decimal">
                        <span class="unit">BUFF</span>
                      </div>
                      <div class="mt-10">
                        <ul class="persent">
                          <li class="cell" :class="{'selected':rate===10}"><button class="cell_btn" @click="setPercent(10)">10%</button></li>
                          <li class="cell" :class="{'selected':rate===25}"><button class="cell_btn" @click="setPercent(25)">25%</button></li>
                          <li class="cell" :class="{'selected':rate===50}"><button class="cell_btn" @click="setPercent(50)">50%</button></li>
                          <li class="cell" :class="{'selected':rate===100}"><button class="cell_btn" @click="setPercent(100)">100%</button></li>
                        </ul>
                      </div>
                      <div class="group mt-10">
                        <div class="m_input_tit">
                          <h4>OTP 인증</h4>
                        </div>
                        <div class="basic_input btn_input_1">
                          <input type="text" placeholder="인증 코드" v-model="otpCode" inputmode="numeric" />
                        </div>
                      </div>
                    </div>
                    <!-- 안내정보 -->
                    <div class="group">
                      <div class="checks_type2">
                        <input type="checkbox" id="agree1" v-model="termsAgree">
                        <label for="agree1">
                          <span style="text-decoration:underline; font-weight: 500;" @click="openTerms">스테이킹 이용약관</span>을 모두 읽고 확인하였습니다.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //내용영역 -->
              <!-- 확인버튼 -->
              <div class="modal_btn bg_primary">
                <div class="btn_table">
                  <div class="btn_col">
                    <button type="button" @click="stakingCf">스테이킹 추가</button>
                  </div>
                </div>
              </div>
              <!-- //확인버튼 -->
              <!-- 닫기 -->
              <div class="modal_close">
                <button type="button" @click="closePopup"><i class="icon-close"></i></button>
              </div>
              <!-- //닫기 -->
            </div>
          </div>
          <!-- //팝업 -->
        </div>
      </div>
    </div>
    <!-- //팝업전체영역 -->
  </div>
</template>

<script>
import StakingTerms from "@/components/staking/StakingTerms";

export default {
  name: "MyPopup",
  components: {
    StakingTerms,
  },
  props: [
    'planDetail',
    'btnType'
  ],
  data() {
    return {
      stakingInfo: [],
      show: false,
      isTermsOpen : false,
      availableBalance: 0,
      availableStaking: 0,
      amount: '',
      rate: '',
      termsAgree: false,
      otpCode: '',
      idx: this.$route.params.idx,
      myBalance: 0,
    }
  },
  methods: {
    handleInput (e) {
      let stringValue = e.target.value.toString()
      let regex = /^\d*(\.\d{1,4})?$/
      if(!stringValue.match(regex) && this.amount!== '') {
        this.amount = this.setDecimal(this.amount, 10000)
      }
    },
    stakingCf(){
      this.$root.$refs.BuffModal.showDialog('스테이킹을 추가 하시겠습니까?', '취소', '추가', ()=>{
        this.goCheckPin()
      }, ()=>{})
    },
    goCheckPin() {
      if(!this.termsAgree){
        this.$notify({type: 'error', text: '스테이킹 이용약관을 확인해 주세요.'})
        return
      }
      if(!this.otpCode){
        this.$notify({type: 'error', text: 'OTP코드를 입력해 주세요.'})
        return
      }
      if(parseFloat(this.availableBalance) < parseFloat(this.amount)) {
        this.$notify({ type: 'error', text: `추가가능한 수량은 ${this.availableBalance}BUFF 입니다.` })
        return
      }
      if(this.amount <= 0){
        this.$notify({ type: 'error', text: '스테이킹 금액은 양수만 입력 가능합니다.' })
        return
      }
      if(parseFloat(this.availableStaking) < parseFloat(this.amount)) {
        let availableStaking = this.setDecimal(parseFloat(this.availableStaking),10000).toFixed(4)
        this.$notify({ type: 'error', text: `스테이킹 가능한 추가수량은 최대 ${availableStaking}BUFF 입니다.` })
        return
      }
      if(this.idx && this.amount && this.otpCode) {
        this.$router.push({
          name: 'Pin',
          params: {
            planIdx: this.idx,
            amount: this.amount,
            otpCode: this.otpCode,
            isPinSet: 2,
            btnType: this.btnType
          }
        })
      } else {
        this.$notify({ type: 'error', text: '빈 칸을 입력해주세요.' })
      }
    },
    setPercent(rate){
      this.amount = this.setDecimal((this.availableBalance * rate/100),10000).toFixed(4)
      this.rate = rate
    },
    getMyBalance(){
      this.$get(this.$MAIN_MYBALANCE_AMOUNT, 'get_my_balance', true, (result) => {
        this.myBalance = this.setDecimal(parseFloat(result.data[0].availableBalance),10000).toFixed(4)
        this.amount = 0
        let maxQuantity = this.setDecimal(parseFloat(this.planDetail.maxQuantity),10000).toFixed(4)
        let stakingAmount = this.setDecimal(parseFloat(this.planDetail.stakingAmount),10000).toFixed(4)
        this.availableStaking = this.setDecimal((maxQuantity - stakingAmount), 10000).toFixed(4)
        if(parseFloat(this.myBalance) > parseFloat(this.availableStaking)){
          this.availableBalance = this.availableStaking
        } else {
          this.availableBalance = this.myBalance
        }
      })
    },
    openTerms() {
      this.isTermsOpen = true
      this.$refs.StakingTerms.showPopup()
    },
    closePopup() {
      this.show = false
      this.$emit('closePopup')
    },
    showPopup() {
      this.show = true
      this.getMyBalance()
    },
  },
  watch: {
    amount(){
      if(this.amount !== this.setDecimal((this.availableBalance * this.rate/100), 10000).toFixed(4)){
        this.rate = ''
      }
    },
  }
}
</script>

<style scoped>

</style>