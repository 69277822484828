<template>
  <div class="wrap">
    <PopupDeposit ref="PopupDeposit" @closePopup=closePopup></PopupDeposit>
    <PopupWithdraw ref="PopupWithdraw" @closePopup=closePopup :availableBalance=availableBalance></PopupWithdraw>
    <PopupLockupDetail ref="PopupLockupDetail" @closePopup=closePopup></PopupLockupDetail>
    <SelectDate ref="SelectDate" @closePopup=closePopup></SelectDate>
    <WalletSearchList ref="WalletSearchList" @closePopup=closePopup></WalletSearchList>

    <div class="bg_gray">
      <!-- 메인페이지  -->
      <div class="layout_home">
        <div class="container">
          <!--          <div class="status_main">-->
          <!--            <div class="status_main_inner">-->
          <div class="status_main" :class="{ 'top_reset' : historyList.length < 2 }" style="padding-bottom: 60rem;">
            <div class="status_main_inner" :class="{ 'top_reset' : historyList.length < 2 }">
              <div class="row_25">
                <div class="home_header clear">
                  <h1 class="logo">
                    <a role="button" @click="$router.push({ name: 'Dashboard' })">
                      <img src="/assets/image/h_logo_w.svg" alt="buff">
                    </a>
                  </h1>
                  <span class="set">
                    <router-link :to="{ name: 'MypageMain' }" class="setting">
                      <img src="/assets/image/setting.svg" alt="마이페이지로 가기">
                    </router-link>
                  </span>
                </div>
                <h3 class="hide">총 락업 나의 자산 확인</h3>
                <!-- 내 보유자산 -->
                <div class="home_lockup mt-30">
                  <div class="total_lockup">
                    <h4 class="subtit white">내 보유자산</h4>
                      <strong class="num_price mt-10">
                        {{ numberWithCommas(setDecimal(((totalBalance) * exchangeRate),1)) }}
                        <!--                        <em class="unit">{{ getCoinLabel() }}</em></strong>-->
                        <em class="unit">KRW</em>
                      </strong>
                    </div>
                </div>
                <!-- 총 보유수량/ 락업수량 -->
                <div class="home_lockup mt-12">
                  <h4 class="subtit white">총 보유수량</h4>
                  <strong class="num_price mt-10">
                    {{ numberWithCommasAndDecimal(totalBalance) }}
                    <em class="unit">{{ getCoinLabel() }}</em>
                  </strong>
                  <div class="view_detail">
                    <button class="fill_btn_bk s_btn radius_s mr-5" @click="openDepositPopup">입금</button>
                    <button class="fill_btn_bk s_btn radius_s" @click="openWithdrawPopup">출금</button>
                  </div>
                </div>
                <!-- 차트영역 -->
                <div class="home_lockup mt-12 chart">
                  <div class="chart_menu">
                    <div>
                      <button class="s_btn radius_s outline_btn_wh" @click="chartDateType = 'DAILY'"
                              :class="{ 'btn_opacity' : chartDateType !== 'DAILY' }">일간</button>
                      <button class="s_btn radius_s outline_btn_wh" @click="chartDateType = 'MONTHLY'"
                              :class="{ 'btn_opacity' : chartDateType !== 'MONTHLY' }">월간</button>
                    </div>
                    <div>
                      <button @click="prevDate">
                        <i class="icon-arrow-left white" style="font-size: 15px;"></i>
                      </button>
                      <span class="white" style="font-size: 15px; margin: 0px 5px" v-if="chartDateType === 'DAILY'">
                        {{ dateSet.replace(/-/g, '.') }}
                      </span>
                      <span class="white" style="font-size: 15px; margin: 0px 5px" v-if="chartDateType === 'MONTHLY'">
                        {{ dateSet.substring(0,7).replace(/-/g, '.') }}
                      </span>
                      <button @click="nextDate" :class="{ 'btn_opacity' : nextBtn }">
                        <i class="icon-arrow-right white" style="font-size: 15px;"></i>
                      </button>
                    </div>
                  </div>
                  <Chart :referenceDate="referenceDate" :chartDateType="chartDateType" />
                </div>
              </div>
            </div>
          </div>
          <div class="status_list" :class="{ 'top_reset' : historyList.length < 2 }">
            <div class="dw_card_container">
              <h2 class="tit">입출금현황</h2>
              <div class="filter">
                <button class="s_btn radius_s outline_btn_gr" @click="openWalletSearchList">입출금 전체</button>
                <!--                <button class="s_btn radius_s outline_btn_gr" @click="$router.push({ name : 'CoinSearchList' })">코인 전체</button>-->
              </div>
              <div class="dw_card_wrap">
                <!-- 입출금현황 -->
                <div class="dw_card_item plus" :class="{ plus: history.isDeposit, minus: !history.isDeposit }"
                     v-for="history in historyList" :key="history.date">
                  <div class="card_head">
                    <div class="dw_tit clear">
                                            <span class="icon_coin">
                                                <img src="/assets/image/ico_buff.svg" alt="메테라">
                                            </span>
                      <div class="address" style="display: flex; align-items: center">
                        <p v-if="history.txid === ''">{{ history.opposite }}</p>
                        <p v-else>{{ history.txid }}</p>
                      </div>
                    </div>
                    <div class="dw_cont">
                      <div class="staking">
                        <strong class="num_price">
                          <em class="unit">{{ history.isDeposit ? '+' : '-' }}</em>
                          {{ numberWithCommasAndDecimal(history.amount) }}
                          <em class="unit">{{ getCoinLabel() }}</em>
                        </strong>
                        <!--                        <span class="rate_result">{{ history.amount * 100 }}<em class="unit">원</em></span>-->
                      </div>
                      <div class="badge">
                        <span>{{ history.typeTrans }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="card_body">
                    <div class="dw_date">
                      <span class="date">{{ history.formatDate }}</span>
                      <button class="tg_btn" @click="history.isTxOpen = !history.isTxOpen; rerenderKey += 1">
                        TXID
                        <i class="icon-arrow-up" v-show="history.isTxOpen"></i>
                        <i class="icon-arrow-down" v-show="!history.isTxOpen"></i>
                      </button>
                    </div>
                    <div class="dw_box" v-show="history.isTxOpen" :key="rerenderKey">
                      <p class="txid_hash">
                        <b>Transaction Hash</b>
                        <span>
                          {{ history.txid ? history.txid : history.type === 'HOUSE_IN' || history.type === 'HOUSE_OUT' ? '내부 전송' : '' }}
                        </span>
                      </p>
                      <p class="txid_status" v-show="history.isDeposit">
                        <b>Status</b>
                        <span>{{ history.confirm }} Confirmations</span>
                      </p>
                      <p class="txid_address" v-show="!history.isDeposit">
                        <b>Wallet Address</b>
                        <span class="w_copy">{{ history.addressTo }}<button class="link_copy"
                                                                            @click="copyAddress(history.addressTo)"><em
                            class="hide">주소복사</em></button></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 더보기 -->
              <div class="card_more">
                <button @click="limit += 10; getWalletHistory()" v-show="total > historyList.length">더보기<i
                    class="icon-arrow-down"></i></button>
                <div class="history_empty">
                  <button v-show="!historyList.length">입출금 내역이 없습니다.</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 하단버튼 영역 -->
      <div class="fixed_container">
        <div class="sw_button_area">
          <div class="sw_button">
            <div class="btn_table">
              <div class="btn_col" @click="$router.push({ name : 'LockupList' })">
                <button class="addStatus"><span class="sw_btn btn1">락업</span></button>
              </div>
              <div class="btn_col" @click="$router.push({ name : 'StakingList' })">
                <button class="addStatus"><span class="sw_btn btn2">스테이킹</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //하단버튼 영역 -->
      <!-- //메인페이지 -->
    </div>
  </div>

</template>

<script>
import PopupDeposit from "@/components/popup/PopupDeposit";
import PopupLockupDetail from "@/components/popup/PopupLockupDetail";
import PopupWithdraw from "@/components/popup/PopupWithdraw";
import SelectDate from "@/components/payment/SelectDate";
import WalletSearchList from "@/components/payment/WalletSearchList";
import Chart from "@/components/Chart";

export default {
  name: "VueDashboard",

  data() {
    return {
      totalBalance: '',
      lockupBalance: '',
      stakingBalance: '',
      availableBalance: '',
      page: 1,
      limit: 10,
      historyList: [],
      isLockupDetailOpen: false,
      isDepositOpen: false,
      isWithdrawOpen: false,
      isWalletRegisterOpen: false,
      rerenderKey: 0,
      total: 0,
      isOTPAuth: false,
      currencyTicker: 'BUFF',
      exchangeTicker: 'KRW',
      exchangeRate: '',
      referenceDate: '',
      chartDateType: 'DAILY',
      dateSet: '',
      nextBtn: false,
    }
  },

  props: ['setDashboardHeader'],

  components: {
    'PopupDeposit': PopupDeposit,
    'PopupLockupDetail': PopupLockupDetail,
    'PopupWithdraw': PopupWithdraw,
    'SelectDate': SelectDate,
    'WalletSearchList': WalletSearchList,
    'Chart': Chart,
  },

  created() {
    this.getWalletHistory()
    this.getRate()

    this.$get(this.$MAIN_DECIMAL_VIEW, 'mainDecimalView', true, (result) => {
      sessionStorage.setItem('decimalView', result.data[0].length)
    }, (result) => {
      console.log(result)
    })
    this.$get(this.$MAIN_BALANCE, 'main_balance', true, (result) => {
      this.lockupBalance = parseFloat(result.data[0].lockupBalance)
      this.availableBalance = parseFloat(result.data[0].availableBalance)
      this.stakingBalance = parseFloat(result.data[0].stakingBalance)
      this.totalBalance = parseFloat(result.data[0].totalBalance)
    }, (result) => {
      console.log(result)
    })
  },

  mounted() {
    this.setDashboardHeader()
    let rd = new Date()
    this.referenceDate = this.dateFormat(rd)
    this.getUserInfo()
  },

  methods: {
    btnDisabled(){
      const today = new Date()
      if(this.chartDateType === 'DAILY'){
        const td = new Date(this.dateFormat(today))
        const rd = new Date(this.referenceDate)
        this.nextBtn = td <= rd
      } else {
        const tm = this.dateFormat(today).split('-')
        const rm = this.referenceDate.split('-')
        if(Number(tm[0]) === Number(rm[0])){
          this.nextBtn = false
          if(Number(tm[1]) <= Number(rm[1])){
            this.nextBtn = true
          }
        } else {
          this.nextBtn = false
        }
      }
    },
    setDate() {
      let referenceDate = this.referenceDate.split('-')
      let y = Number(referenceDate[0])
      let m = Number(referenceDate[1]) - 1
      let d = Number(referenceDate[2])
      let date = new Date(y, m, d);
      if (this.chartDateType === 'DAILY') {
        date.setDate(date.getDate() - 1);
      } else {
        date.setMonth(date.getMonth() - 1);
      }
      this.dateSet = this.dateFormat(date)
      this.btnDisabled()
    },
    prevDate() {
      let referenceDate = this.referenceDate.split('-')
      let y = Number(referenceDate[0])
      let m = Number(referenceDate[1]) - 1
      let d = Number(referenceDate[2])
      let date = new Date(y, m, d);
      if (this.chartDateType === 'DAILY') {
        date.setDate(date.getDate() - 1);
      } else {
        date.setMonth(date.getMonth() - 1);
      }
      this.referenceDate = this.dateFormat(date)
    },
    nextDate() {
      if(this.nextBtn){
        return
      }
      let referenceDate = this.referenceDate.split('-')
      let y = Number(referenceDate[0])
      let m = Number(referenceDate[1]) - 1
      let d = Number(referenceDate[2])
      let date = new Date(y, m, d);
      if (this.chartDateType === 'DAILY') {
        date.setDate(date.getDate() + 1);
      } else {
        date.setMonth(date.getMonth() + 1);
      }
      this.referenceDate = this.dateFormat(date)
    },
    getRate() {
      this.$get(`${this.$MAIN_CURRENCY_RATE}?currencyTicker=${this.currencyTicker}&exchangeTicker=${this.exchangeTicker}`, 'rate', true, (result) => {
        this.exchangeRate = result.data[0].exchangeRate
        // this.exchangeRate = this.exchangeRate.toFixed(10)
        console.log(this.exchangeRate)
      }, (result) => {
        console.log(result)
      })
    },
    getUserInfo() {
      this.getUserProfile((result) => {
        this.isOTPAuth = result.userProfile.isOTPAuth
        console.log(result.userProfile)
      })
    },
    closePopup() {
      this.isDepositOpen = false
      this.isWithdrawOpen = false
      this.isLockupDetailOpen = false
      this.isWalletRegisterOpen = false
    },

    openDepositPopup() {
      this.isDepositOpen = true
      this.$refs.PopupDeposit.showPopup()
    },

    openWithdrawPopup() {
      if (this.isOTPAuth) {
        this.isWithdrawOpen = true
        this.$refs.PopupWithdraw.showPopup()
      } else {
        this.$router.push({name: 'MypageOTPSetting'})
        // alert('OTP 등록이 완료되어야 출금이 가능합니다.')
      }
    },

    openLockupDetail() {
      this.isLockupDetailOpen = true
      this.$refs.PopupLockupDetail.showPopup()
    },

    openWalletRegister() {
      this.isSelectDateOpen = true
      this.$refs.SelectDate.showPopup()
    },

    openWalletSearchList() {
      this.isWalletRegisterOpen = true
      this.$refs.WalletSearchList.showPopup()
    },

    getWalletHistory() {
      this.$get(`${this.$MAIN_WALLET_HISTORY}?page=${this.page}&limit=${this.limit}`, 'dashboard', true, (result) => {
        console.log(result)
        this.historyList = result.data
        this.total = result.total
        this.historyList.forEach((history) => {
          history.isTxOpen = false

          let transferType = history.type.split('_')[1]
          if (transferType === 'IN') {
            history.isDeposit = true
            if (history.processState === 'PENDING') {
              history.typeTrans = '입금 대기중'
            } else if (history.processState === 'DONE') {
              history.typeTrans = '입금 완료'
            } else if (history.processState === 'ERROR') {
              history.typeTrans = '입금 에러'
            } else if (history.processState === 'PROCESSING') {
              history.typeTrans = '입금 진행중'
            } else if (history.processState === 'DECLINED') {
              history.typeTrans = '입금 거절'
            } else {
              history.typeTrans = ''
            }
          } else {
            history.isDeposit = false
            if (history.processState === 'PENDING') {
              history.typeTrans = '출금 대기중'
            } else if (history.processState === 'DONE') {
              history.typeTrans = '출금 완료'
            } else if (history.processState === 'ERROR') {
              history.typeTrans = '출금 에러'
            } else if (history.processState === 'PROCESSING') {
              history.typeTrans = '출금 진행중'
            } else if (history.processState === 'DECLINED') {
              history.typeTrans = '출금 거절'
            } else {
              history.typeTrans = ''
            }
          }

          history.formatDate = this.formatDate(history.date)

          if (history.txid === 'safe-withdraw-pending') {
            history.txid = ''
          } else if (history.txid === 'internal') {
            history.txid = ''
          }
        })
      }, (result) => {
        console.log(result)
        this.httpError(result)
      })
    },

    copyAddress(addressTo) {
      let tempInput = document.createElement('input')
      tempInput.value = addressTo
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.$notify({type: 'success', text: '복사되었습니다.'})
    },
  },
  watch: {
    referenceDate(){
      this.setDate()
    },
    chartDateType(){
      let rd = new Date()
      this.referenceDate = this.dateFormat(rd)
      this.btnDisabled()
    }
  }
}
</script>

<style scoped>
/*.history_empty {*/
/*  height: 280px;*/
/*}*/
.top_reset {
  top: 0px;
}
.chart {
  height: 280px;
  position: relative;
}
.outline_btn_wh {
  display: inline-block;
  color: var(--white);
  border: 1px solid var(--white);
  margin-right: 10px;
}
.btn_opacity {
  opacity: 0.5;
}
.chart_menu {
  display: flex;
  justify-content: space-between;
}
</style>